/**
 * Main Javascript File
 *
 * v1.0.0
 */
$ = jQuery.noConflict();

var mainScript = function () {
    'use strict';


    /**
     * init page
     */
    var init = function () {

        var map = null;

        // enable SVG use tag for legacy browsers
        svg4everybody();

        init_member_login();

        // Cookie Law
        cookie_law();

        // Init Navigatino
        init_nav();

        init_warteliste();

        // Scroll Function
        scroll_to_position();

        // Google Maps
        init_maps();

        // Filter
        init_filter();

        console.log('main.js: page initialized');


    };


    /**
     * Member Login
     */
    var init_member_login = function () {

        // Perform AJAX login on form submit
        jQuery('form#memberLogin').on('submit', function (e) {

            $(this).removeClass('invalid');

            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: ajax_object.ajax_url,
                data: {
                    'action': 'memberLogin', //calls wp_ajax_nopriv_memberLogin
                    'email': jQuery('form#memberLogin #memberEmail').val(),
                    'password': jQuery('form#memberLogin #memberPassword').val(),
                    'security': jQuery('form#memberLogin #Glxdvda1').val()
                },
                beforeSend: function () {
                    jQuery('form#memberLogin button[type="submit"]').removeClass('btn-loading').addClass('btn-loading');
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log(jqXHR + " :: " + textStatus + " :: " + errorThrown);
                },
                success: function (data) {
                    console.log(data);

                    // TRUE
                    if (data.loggedin == true) {

                        setTimeout(function () {

                            setTimeout(function () {
                                jQuery('form#memberLogin button[type="submit"]').addClass('btn-okay btn-success');
                                document.location.href = ajax_object.ajax_redirect;

                            }, 300); // Animation Timeout

                        }, 300); // Animation Timeout

                        // FALSE
                    } else {

                        setTimeout(function () {

                            jQuery('form#memberLogin button[type="submit"]').removeClass('btn-loading');
                            jQuery('form#memberLogin #memberPassword').val('')

                            $('form#memberLogin').addClass('invalid');

                        }, 300); // Animation Timeout
                    }

                }
            });

            e.preventDefault();
        });

    };


    /**
     * Cookie Consent
     */
    var cookie_law = function () {

        window.addEventListener("load", function () {
            window.cookieconsent.initialise({
                theme: "custom",
                position: "bottom-right",
                content: {
                    message: "Diese Website verwendet Cookies um sicherzustellen, dass Sie die besten Erfahrungen auf unserer Website erhalten.",
                    dismiss: "Zustimmen",
                    link: false
                },
                revokable: true,
                onStatusChange: function (status) {
                    console.log(this.hasConsented() ?
                        'enable cookies' : 'disable cookies');
                },
                elements: {
                    dismiss: '<a aria-label="dismiss cookie message" tabindex="0" class="btn btn-sm btn-primary cc-btn cc-dismiss">{{dismiss}}</a>',
                }
            })
        });

    };


    /**
     * Warteliste
     */
    var init_warteliste = function () {

        $('#modalWarteliste').on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget) // Button that triggered the modal
            var recipient = button.data('whatever') // Extract info from data-* attributes
            // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
            // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
            var modal = $(this);
            modal.find('.modal-body #whatever').val(recipient);
        })

    };


    /**
     * Init Nav
     */
    var init_nav = function () {

        $('.hamburger, .navbar-backdrop').click(function () {

            $('.hamburger').toggleClass('is-active not-active');
            $('body').toggleClass('nav-xs-hidden nav-xs-show');

            return false;
        });

        $('.dropdown-toggle').dropdown();

    };


    /**
     * Init Scroll To
     */
    var scroll_to_position = function () {


        $('.scroll-link').on('click', function () {

            var id = $(this).attr('href');

            $('body, html').animate({
                scrollTop: $(id).offset().top
            }, 1000, 'easeInOutExpo');

            return false;
        });

    };


    /**
     * Init Map
     */
    var init_maps = function () {

        var map;

        /*
        $('.map').each(function () {

            // create map
            map = new_map($(this));

        }); */

        $('.collapse').on('shown.bs.collapse', function () {

            console.log('collapse map trigger');

            var map = $(this).find('.map'),
                mapReady = false,
                hasMarker = map.find('.marker');

            if(hasMarker.length >=1 ){

                new_map(map);
                mapReady = true;

            }
            //else {

                //google.maps.event.trigger(map, 'resize');
                //center_map(map);

            //}

        });

    };

    /**
     * Mix it up Filter
     */
    var init_filter = function () {

        var el = dh.select('.mixit');
        if (el.length === 0) {
            // No form on this page --> skip
            return null;
        }

        var iso = new Isotope( '.mixit', {
            itemSelector: '.mix',
            layoutMode: 'fitRows',
            animationEngine : 'css',
            transitionDuration: 0
        });

        // bind filter button click
        var filtersElem = document.querySelector('.filters-button-group');
        filtersElem.addEventListener( 'click', function( event ) {
            // only work with buttons
            if ( !matchesSelector( event.target, 'button' ) ) {
                return;
            }
            var filterValue = event.target.getAttribute('data-filter');
            iso.arrange({ filter: filterValue });
        });

        // change is-checked class on buttons
        var buttonGroups = document.querySelectorAll('.button-group');
        for ( var i=0, len = buttonGroups.length; i < len; i++ ) {
            var buttonGroup = buttonGroups[i];
            radioButtonGroup( buttonGroup );
        }

        function radioButtonGroup( buttonGroup ) {
            buttonGroup.addEventListener( 'click', function( event ) {
                // only work with buttons
                if ( !matchesSelector( event.target, 'button' ) ) {
                    return;
                }
                buttonGroup.querySelector('.is-checked').classList.remove('is-checked');
                event.target.classList.add('is-checked');
            });
        }


        $('.collapse').on('shown.bs.collapse', function(iso) {
            var iso = new Isotope( '.mixit', {
                itemSelector: '.mix',
                layoutMode: 'fitRows',
                animationEngine : 'css',
                transitionDuration: 0
            });
        });

        $('.collapse').on('hidden.bs.collapse', function(iso) {
            var iso = new Isotope( '.mixit', {
                itemSelector: '.mix',
                layoutMode: 'fitRows',
                animationEngine : 'css',
                transitionDuration: 0
            });
        });

    };


    /*
     *
     * Render Map
     *
     */
    var render_map = function ($el) {

        // var
        var $markers = $el.find('.marker');

        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        };

        // create map
        var map = new google.maps.Map($el[0], args);

        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function () {

            add_marker($(this), map);

        });

        // center map
        center_map(map);


    };

    /*
     *  new_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$el (jQuery element)
     *  @return	n/a
     */

    var new_map = function ($el) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        };


        // create map
        var map = new google.maps.Map($el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function () {

            add_marker($(this), map);

        });


        // center map
        center_map(map);


        // return
        return map;

    };

    /*
     *  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    var add_marker = function ($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

                infowindow.open(map, marker);

            });
        }

    };

    /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    var center_map = function (map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        }
        else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    };


    /**
     * Public
     */
    return {
        init: init
    };

};


// Create mainScript object
window.k = window.k || new mainScript();
console.log('Main Script');      //, k);
dh.ready(k.init);